/* CoinflipCard.css */

.coinflip-card {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.coinflip-card p {
    margin: 5px 0;
}

.coinflip-card p strong {
    font-weight: bold;
    color: #333;
}
