.progress-bar-container {
    width: 100%;
    height: 5px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    overflow: hidden; /* Hide overflowing progress bar */
}

.progress-bar {
    height: 100%;
    background-color: white;
    transition: width 1s linear; /* Smooth transition for width change */
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    background-color: #0047FF;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 5px;
    outline: none;
}

.pagination button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}
